<!-- eslint-disable vue/no-unused-components -->
<template>
  <b-modal
    v-model="modalItem"
    :title="
      editable
        ? 'Editar custo'
        : modalItemForm
        ? 'Cadastrar custo de projeto'
        : 'Excluir imposto'
    "
    class="text-center text-primary d-flex align-items-center"
    no-close-on-backdrop
    hide-footer
    hide-header-close
  >
    <validation-observer ref="ModalProjectCosts">
      <b-form v-if="modalItemForm">
        <b-row
          class="my-1 m-2 text-left mt-4 d-flex justify-content-center flex-column"
        >
          <!-- GROUP -->
          <b-form-group v-slot="{ ariaDescribedby }" class="mb-2">
            <label class="font-weight-bold text-primary">Tipo de Custo:</label>
            <b-form-radio-group disabled v-model="modelItem.group">
              <b-form-radio
                name="selected-prefix-tax"
                :aria-describedby="ariaDescribedby"
                value="projects"
                >Projeto</b-form-radio
              >
            </b-form-radio-group>
          </b-form-group>

          <!-- PROJETOS -->
          <b-form-group v-if="modelItem.group !== 'offices'" class="mb-2">
            <label for="category" class="font-weight-bold text-primary"
              >Projetos :</label
            >
            <b-form-select id="category" v-model="modelItem.project_id">
              <option
                v-for="project in projects"
                :key="project.id"
                :value="project.id"
              >
                {{ project.project_code }} - {{ project.project_name }}
              </option>
            </b-form-select>
          </b-form-group>

          <!-- FORNECEDORES -->
          <b-form-group class="mb-2">
            <label for="category" class="font-weight-bold text-primary"
              >Fornecedor :</label
            >
            <b-form-select id="category" v-model="modelItem.supplier_id">
              <option
                v-for="supplier in supplier"
                :key="supplier.id"
                :value="supplier.id"
              >
                {{ supplier.supplier_name }}
              </option>
            </b-form-select>
          </b-form-group>

          <!-- CATEGORIAS -->
          <b-form-group class="mb-2">
            <label for="category" class="font-weight-bold text-primary"
              >Categoria :</label
            >
            <b-form-select
              id="category"
              placeholder="Selecione uma categoria"
              v-model="modelItem.expense_category_id"
            >
              <option
                v-for="categories in categories"
                :key="categories.id"
                :value="categories.id"
              >
                {{ categories.expense_category_name }}
              </option>
            </b-form-select>
          </b-form-group>

          <!-- VALOR -->
          <b-form-group class="mb-2" :disabled="modelItem.isRepeatActive">
            <label for="value" class="font-weight-bold text-primary"
              >Valor Monetário:</label
            >
            <b-input-group>
              <b-form-input
                id="value"
                type="text"
                v-model="modelItem.value"
                v-money="moneyMask"
              ></b-form-input>
            </b-input-group>
          </b-form-group>

          <!-- DATA DE REFERÊNCIA -->
          <b-form-group class="mb-2" :disabled="modelItem.isRepeatActive">
            <label for="referenceDate" class="font-weight-bold text-primary"
              >Data de Referência:</label
            >
            <b-form-input
              id="referenceDate"
              type="date"
              v-model="modelItem.reference_date"
            ></b-form-input>
          </b-form-group>

          <!-- DATA DE Transação -->
          <b-form-group class="mb-2">
            <label for="transactionDate" class="font-weight-bold text-primary"
              >Data da Transação:</label
            >
            <b-form-input
              id="transactionDate"
              type="date"
              v-model="modelItem.transaction_date"
            ></b-form-input>
          </b-form-group>

          <!-- STATUS -->
          <b-form-group v-slot="{ ariaDescribedby }" class="mb-2">
            <label class="font-weight-bold text-primary">Status:</label>
            <b-form-radio-group v-model="modelItem.status">
              <b-form-radio
                name="selected-prefix-will"
                :aria-describedby="ariaDescribedby"
                value="previsto"
                >Previsto</b-form-radio
              >
              <b-form-radio
                name="selected-prefix-was"
                :aria-describedby="ariaDescribedby"
                value="realizado"
                >Realizado</b-form-radio
              >
            </b-form-radio-group>
          </b-form-group>

          <!-- DESCRICAO -->
          <b-form-group class="mb-2">
            <label class="font-weight-bold text-primary">Descrição:</label>
            <b-form-textarea v-model="modelItem.description"></b-form-textarea>
          </b-form-group>
        </b-row>

        <hr class="invoice-spacing mb-3" />

        <b-row class="justify-content-between d-flex p-2">
          <!-- back -->
          <b-button
            :disabled="loading"
            variant="warning"
            class="mr-2"
            @click="closeModal()"
            >Voltar
          </b-button>

          <b-button
            v-if="editable && !modelItem.is_blocked"
            variant="danger"
            @click="() => (modalInactivate = true)"
            :disabled="modelItem.status.length < 1 || loading"
          >
            <span v-if="!loading"> Excluir custo </span>
            <b-spinner class="spinner" v-if="loading"></b-spinner>
          </b-button>

          <div class="d-flex justify-content-between">
            <b-button
              v-if="editable && !modelItem.is_blocked"
              variant="primary"
              @click="updateProjectCosts"
              :disabled="modelItem.status.length < 1 || loading"
            >
              <span v-if="!loading"> Salvar Alterações </span>
              <b-spinner class="spinner" v-if="loading"></b-spinner>
            </b-button>

            <!-- saveUser -->

            <b-button
              v-if="!editable"
              variant="primary"
              @click="saveProjectCosts"
              :disabled="loading"
            >
              <span v-if="!loading">Salvar</span>
              <b-spinner v-if="loading"></b-spinner>
            </b-button>
          </div>
          <!-- modalInativate -->
          <b-modal
            title="Excluir custo"
            no-close-on-backdrop
            hide-footer
            hide-header-close
            v-model="modalInactivate"
          >
            <b-row class="mt-1 justify-content-center d-flex text-center">
              <p>
                Deseja realmente
                <strong>{{
                  modelItem.is_blocked ? 'excluir' : 'inativar'
                }}</strong>
                este custo ?
              </p>
            </b-row>
            <hr class="invoice-spacing mb-3" />
            <b-row class="justify-content-between d-flex mt-3 p-1">
              <b-button variant="warning" @click="modalInactivate = false">
                Cancelar
              </b-button>
              <b-button
                variant="primary"
                @click="
                  modelItem.is_blocked ? activeCosts() : removeProjectCosts()
                "
              >
                Confirmar
              </b-button>
            </b-row>
          </b-modal>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BRow,
  BFormGroup,
  BFormSelect,
  BFormRadioGroup,
  BFormInput,
  BFormTextarea,
  BFormRadio,
  BButton,
  BInputGroup,
  BSpinner,
} from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { ValidationObserver } from 'vee-validate';
import { required } from '@validations';

export default {
  name: 'new-projectCosts',
  props: {
    editable: {
      Type: Boolean,
      default: false,
    },
    modalItem: {
      Type: Boolean,
      default: false,
    },
    modalItemForm: {
      Type: Boolean,
      default: false,
    },
    modalOpenToDeleted: {
      Type: Boolean,
      default: false,
    },
    itemUpdate: {
      type: Object && String && Number,
      default: null,
    },
  },

  components: {
    BModal,
    BForm,
    BRow,
    // BCol,
    BFormGroup,
    BFormRadio,
    BFormSelect,
    BButton,
    ValidationObserver,
    BFormRadioGroup,
    BSpinner,
    BFormInput,
    BInputGroup,
    BFormTextarea,
  },

  data() {
    return {
      modelItem: {
        value: 0,
        reference_date: '',
        transaction_date: '',
        status: '',
        description: '',
        repeat: 0,
        group: 'projects',
        supplier_id: '',
        expense_category_id: '',
        project_id: '',
        is_blocked: false,
      },
      modelItemDefault: {
        value: 0,
        reference_date: '',
        transaction_date: '',
        status: 'Realizado',
        description: '',
        repeat: 0,
        group: 'projects',
        supplier_id: '',
        expense_category_id: '',
        project_id: '',
        is_blocked: false,
      },
      projects: [],
      supplier: [],
      categories: [],
      modalInactivate: false,
      loading: false,
      required,
      moneyMask: {
        prefix: 'R$ ',
        suffix: '',
        thousands: '.',
        decimal: ',',
        precision: 2,
        masked: false,
      },
    };
  },
  watch: {
    modalItemForm(newVal) {
      if (newVal) {
        this.loadProjects();
      }
    },
    modalItem(newVal) {
      if (newVal) {
        this.loadProjects();
      }
    },

    editable(itemUpdate) {
      if (itemUpdate) {
        this.getProjectCosts();
      }
    },
  },

  computed: {
    currentWorkspace() {
      return this.$store.getters.currentWorkspace;
    },
  },
  created() {
    // this.loadProjects();
  },
  beforeMount() {
    if (this.modalItemForm) {
      this.loadProjects();
    }

    if (this.editable) {
      this.getProjectCosts();
    }
  },

  methods: {
    modalOpenDelete() {
      this.modalOpenToDeleted = true;
    },
    closeModal() {
      this.modelItem = this.modelItemDefault;
      this.$emit('closeModal');
    },
    saveProjectCosts() {
      this.$refs.ModalProjectCosts.validate().then((success) => {
        if (success) {
          this.modelItem.workspace_id = this.currentWorkspace.id;
          this.$store
            .dispatch('saveProjectCosts', {
              ...this.modelItem,
              expense: true,
              project_id:
                this.modelItem.group === 'offices'
                  ? ''
                  : this.modelItem.project_id,
              value: this.modelItem.value
                .replace('R$ ', '')
                .replace(',', '')
                .replace('.', '')
                .replace('.', '')
                .toString(),
              type: 'À vista (uma vez)',
            })
            .then((resp) => {
              this.loading = false;
              if (resp) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Custo cadastrado com sucesso',
                    icon: 'SuccessIcon',
                    variant: 'success',
                  },
                });
                this.modelItem = this.modelItemDefault;
                this.$emit('closeModal');
                this.$emit('updateList');
              }
            })
            .catch(() => {
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Erro ao cadastrar custo',
                  icon: 'XIcon',
                  variant: 'danger',
                },
              });
            });
        }
      });
    },

    getProjectCosts() {
      this.loading = true;

      let cost_id = this.itemUpdate;

      this.$store
        .dispatch('getProjectCosts', {
          workspace_id: this.$store.getters.currentWorkspace.id,
          id: cost_id,
        })
        .then((resp) => {
          this.loading = false;
          this.modelItem = {
            ...resp,
            reference_date: this.dateFormatter(
              resp.reference_date,
              'yyyy-MM-dd'
            ),
            value: this.numberToMonetary(resp.value),
            transaction_date: this.dateFormatter(
              resp.transaction_date,
              'yyyy-MM-dd'
            ),
          };
        })
        .catch(() => {
          this.loading = false;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao buscar custo',
              icon: 'XIcon',
              variant: 'danger',
            },
          });
        });
    },
    updateProjectCosts() {
      this.$refs.ModalProjectCosts.validate().then((success) => {
        if (success) {
          this.loading = true;
          this.$store
            .dispatch('updateProjectCosts', {
              ...this.modelItem,
              value: this.modelItem.value
                .replace('R$ ', '')
                .replace('.', '')
                .replace('.', '')
                .replace(',', ''),
            })
            .then((resp) => {
              this.loading = false;
              if (resp) {
                this.$emit('renderList');
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Custo atualizado com sucesso',
                    icon: 'SuccessIcon',
                    variant: 'success',
                  },
                });
                this.$emit('closeModal');
                this.modelItem = this.modelItemDefault;
              }
            })
            .catch(() => {
              this.loading = false;

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'erro ao atualizar o imposto',
                  icon: 'XIcon',
                  variant: 'danger',
                },
              });
            });
        }
      });
    },
    inactivateProjectCosts() {
      this.loading = true;
      this.$store
        .dispatch('inactivateProjectCosts', this.modelItem)
        .then((resp) => {
          this.loading = false;
          if (resp) {
            this.modalInactivate = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Custos inativada com sucesso',
                icon: 'SuccessIcon',
                variant: 'success',
              },
            });
            this.$router.push({ name: 'taxes' });
          }
        })
        .catch(() => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'erro ao inativa custo',
              icon: 'XIcon',
              variant: 'danger',
            },
          });
        });
    },
    activeCosts() {
      this.$refs.ModalProjectCosts.validate().then((success) => {
        if (success) {
          this.loading = true;
          this.$store
            .dispatch('activeCosts', this.itemUpdate)
            .then((resp) => {
              this.loading = false;
              if (resp) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Custo reativado com sucesso',
                    icon: 'SuccessIcon',
                    variant: 'success',
                  },
                });
                this.$router.push({ name: 'taxes' });
              }
            })
            .catch(() => {
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'erro ao reativar custo',
                  icon: 'XIcon',
                  variant: 'danger',
                },
              });
            });
        }
      });
    },
    handleRepeatChange() {
      this.modelItem.isRepeatActive = this.modelItem.repeat === '0';
    },
    handleKeyPress() {
      // console.log({ repeat: this.modelItem, e });
      // this.repeatData
    },
    removeProjectCosts() {
      console.log('inativar', this.itemUpdate);
      this.$refs.ModalProjectCosts.validate().then((success) => {
        if (success) {
          this.loading = true;
          this.$store
            .dispatch('removeProjectCosts', { id: this.itemUpdate })
            .then((resp) => {
              this.loading = false;
              if (resp) {
                this.$emit('renderList');
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Custo removido com sucesso',
                    icon: 'SuccessIcon',
                    variant: 'success',
                  },
                });
                this.$emit('closeModal');
                this.modalInactivate = false;
                this.modelItem = this.modelItemDefault;
              }
            })
            .catch(() => {
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Erro ao deletar custo',
                  icon: 'XIcon',
                  variant: 'danger',
                },
              });
            });
          this.$emit('closeModal');
          this.modelItem = this.modelItemDefault;
        }
      });
    },
    async loadProjects() {
      try {
        await this.$store
          .dispatch('_getProjects', {
            workspace_id: this.$store.getters.currentWorkspace.id,
          })
          .then((resp) => {
            this.loading = false;
            this.projects = resp.data;
          });

        await this.$store
          .dispatch('getAllSuppliers', {
            workspace_id: this.$store.getters.currentWorkspace.id,
          })
          .then((resp) => {
            if (resp) {
              this.supplier = resp;
            }
          });

        await this.$store
          .dispatch('getAllCostsCategory', {
            inactive: false,
            workspace_id: this.$store.getters.currentWorkspace.id,
            cost_category: 'project',
          })
          .then((resp) => {
            if (resp) {
              this.categories = resp.data;
            }
          });
      } catch (error) {
        console.error('Erro ao carregar projetos', error);
      }
    },
  },
};
</script>
